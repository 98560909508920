import axiosPost from "./axiosPost";

export const nivelModalidadIns = async (credentials, data) => {
    const body = {
        service: "NQNNivelModalidadIns",
        params: {
            nqnNivelModalidadNombre: data?.nombre,
            nqnNivelModalidadCod: data?.cod,
            nqnNivelModalidadActivo: data?.activo,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};